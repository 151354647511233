// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backhaul-ready-bulk-update-input > * {
    margin-bottom: 30px;
  }
  .backhaul-ready-bulk-update-input__textarea {
    padding: 20px;
    border: 1px solid var(--color-grayscale-5);
    width: 600px;
    height: 200px;
    outline: none;
  }
  .backhaul-ready-bulk-update-input__textarea.error {
    border: 1px solid red;
  }
  .backhaul-ready-bulk-update-input__error {
    color: red;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BackhaulReadyBulkUpdate/features/BackhaulReadyBulkUpdateInput/style.css"],"names":[],"mappings":"AACE;IACE,mBAAmB;EACrB;EAEA;IACE,aAAa;IACb,0CAA0C;IAC1C,YAAY;IACZ,aAAa;IACb,aAAa;EACf;EAEA;IACE,qBAAqB;EACvB;EAEA;IACE,UAAU;EACZ","sourcesContent":[".backhaul-ready-bulk-update-input {\n  & > * {\n    margin-bottom: 30px;\n  }\n\n  &__textarea {\n    padding: 20px;\n    border: 1px solid var(--color-grayscale-5);\n    width: 600px;\n    height: 200px;\n    outline: none;\n  }\n\n  &__textarea.error {\n    border: 1px solid red;\n  }\n\n  &__error {\n    color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
