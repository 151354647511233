import { combineReducers } from '@reduxjs/toolkit';
import * as actionLogEntities from './entities/action-logs/slices';
import * as changeLogEntities from './entities/change-logs/slices';

export const adminActionLogsStoreKey = 'adminActionLogs';

export default combineReducers({
  [actionLogEntities.legActionLogsEntityKey]: actionLogEntities.legActionLogsReducer,
  [changeLogEntities.changeLogsEntityKey]: changeLogEntities.changelogsReducer
});
