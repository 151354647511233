import { createSlice } from '@reduxjs/toolkit';

import getInitialAsyncSliceState from '@client/_blessed/store/utils/getInitialAsyncAtomState';
import { retrieveChangeLogs } from './actions';
// import entityAdapter from './adapter';

export const changeLogsEntityKey = 'changeLogs';

export const changeLogsSlice = createSlice({
  name: changeLogsEntityKey,
  initialState: getInitialAsyncSliceState(),
  reducers: {
    reset: () => getInitialAsyncSliceState()
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveChangeLogs.pending, (state) => {
      state.error = null;
      state.loading += 1;
    });
    builder.addCase(retrieveChangeLogs.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.loading -= 1;
    });
    builder.addCase(retrieveChangeLogs.rejected, (state, action) => {
      state.error = action.payload;
      state.loading -= 1;
    });
  }
});

export const changelogsReducer = changeLogsSlice.reducer;
export const changeLogsActions = changeLogsSlice.actions;
