// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backhaul-ready-bulk-update-tables > * {
        margin-bottom: 30px;
    }
    .backhaul-ready-bulk-update-tables__table-title {
       display: flex;
       justify-content: space-between;
       margin-bottom: 20px;
       font-size: 16px;
       font-weight: 500;
    }
    .backhaul-ready-bulk-update-tables__table {
       border: 1px solid var(--color-grayscale-5);
       width: 100%;
       text-align: left;
  }
    .backhaul-ready-bulk-update-tables__table th {
       font-weight: 500;
       background: var(--color-grayscale-2);
    }
    .backhaul-ready-bulk-update-tables__table th, .backhaul-ready-bulk-update-tables__table td {
       padding: 15px 20px;
    }
    .backhaul-ready-bulk-update-tables__table > tr {
       border: 1px solid #ccc;
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BackhaulReadyBulkUpdate/features/BackhaulReadyBulkUpdateTables/style.css"],"names":[],"mappings":"AACI;QACI,mBAAmB;IACvB;IAEA;OACG,aAAa;OACb,8BAA8B;OAC9B,mBAAmB;OACnB,eAAe;OACf,gBAAgB;IACnB;IAEA;OACG,0CAA0C;OAC1C,WAAW;OACX,gBAAgB;EAcrB;IAZE;OACG,gBAAgB;OAChB,oCAAoC;IACvC;IAEA;OACG,kBAAkB;IACrB;IAEA;OACG,sBAAsB;IACzB","sourcesContent":[".backhaul-ready-bulk-update-tables {\n    & > * {\n        margin-bottom: 30px;\n    }\n\n    &__table-title {\n       display: flex;\n       justify-content: space-between;\n       margin-bottom: 20px;\n       font-size: 16px;\n       font-weight: 500;\n    }\n\n    &__table {\n       border: 1px solid var(--color-grayscale-5);\n       width: 100%;\n       text-align: left;\n\n    & th {\n       font-weight: 500;\n       background: var(--color-grayscale-2);\n    }\n\n    & th, td {\n       padding: 15px 20px;\n    }\n\n    & > tr {\n       border: 1px solid #ccc;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
