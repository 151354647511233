import { createSlice } from '@reduxjs/toolkit';
import getInitialStoreSliceState from '@client/_blessed/store/utils/getInitialAsyncAtomState';
import { retrieveLegActionLogs, retrieveLegActionLogsByOrder } from './actions';

export const legActionLogsEntityKey = 'legActionLogs';

export const legActionLogsSlice = createSlice({
  name: legActionLogsEntityKey,
  // initialState: entityAdapter.getInitialState(getInitialStoreSliceState()),
  initialState: getInitialStoreSliceState(),
  reducers: {
    reset: () => getInitialStoreSliceState()
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveLegActionLogs.pending, (state) => {
      state.error = null;
      state.loading += 1;
    });
    builder.addCase(retrieveLegActionLogs.fulfilled, (state, action) => {
      // entityAdapter.setAll(state, action.payload);
      state.data = action.payload;
      state.error = null;
      state.loading -= 1;
    });
    builder.addCase(retrieveLegActionLogs.rejected, (state) => {
      // state.error = action.payload;
      state.loading -= 1;
    });
    builder.addCase(retrieveLegActionLogsByOrder.pending, (state) => {
      state.error = null;
      state.loading += 1;
    });
    builder.addCase(retrieveLegActionLogsByOrder.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.loading -= 1;
    });
    builder.addCase(retrieveLegActionLogsByOrder.rejected, (state, action) => {
      state.error = action.payload;
      state.loading -= 1;
    });
  }
});

export const legActionLogsReducer = legActionLogsSlice.reducer;
export const legActionLogsActions = legActionLogsSlice.actions;
