import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AdminLeftSidebar from './features/AdminLeftSideBar';
import YardContainers from './features/YardContainers';
import DualTransFinder from './features/DualTransFinder';
import ReactiveDualTrans from './features/ReactiveDualTrans';
import ActionLogs from './features/ActionLogs';
import EmptyReturnAcceptance from './features/EmptyReturnAcceptance';
import ShipETAs from './features/ShipETAs';
import BundleGenerator from './features/BundleGenerator';
import BundleGeneratorNotifications from './features/BundleGeneratorNotifications';
import BackhaulReadyBulkUpdate from './features/BackhaulReadyBulkUpdate';
import OnHireEntry from './features/OnHireEntry';
import OnHireList from './features/OnHireList';
import BulkOrderCancellation from './features/BulkOrderCancellation';
import ChassisManagement from './features/ChassisManagement';
import ChassisPoolsManagement from './features/ChassisPoolsManagement';
import AppointmentBooking from './features/Scraper/features/AppointmentBooking';
import BulkOperationPage from './features/BulkOperationPage';
import UpdateTermsOfService from '../../../../pages/UpdateTermsOfServicePage';

import './style.css';
import { BULK_OPERATION_TYPES } from './features/BulkOperationPage/store/types/BulkOperationTypes';

export interface AdminProps extends RouteComponentProps<any> {}

function Admin(props: AdminProps) {
  const baseClassName = 'Admin';
  const classNames = {
    root: baseClassName,
    content: `${baseClassName}__content`
  };

  const { showFscUpdatePage, showLoadIdUpdatePage, showInvoicePdfGenerationPage, showOrderStatusUpdatePage } =
    useFlags();

  return (
    <div className={classNames.root}>
      <Route
        exact
        path={`${props.match?.path}/:featureID`}
        render={(selfProps) => <AdminLeftSidebar {...selfProps} baseRoutePath={props.match?.path} />}
      />
      <div className={classNames.content}>
        <Switch>
          <Redirect exact from={props.match?.path} to={`${props.match?.path}/action-logs`} />
          <Route exact path={`${props.match?.path}/action-logs`} component={ActionLogs} />
          <Route exact path={`${props.match?.path}/fsc-update`}>
            <BulkOperationPage
              title="FSC Update"
              operationType={BULK_OPERATION_TYPES.FSC_UPDATE}
              isEnabled={showFscUpdatePage}
            />
          </Route>
          <Route exact path={`${props.match?.path}/load-id-update`}>
            <BulkOperationPage
              title="Load Id Update"
              operationType={BULK_OPERATION_TYPES.LOAD_ID_UPDATE}
              isEnabled={showLoadIdUpdatePage}
            />
          </Route>
          <Route exact path={`${props.match?.path}/invoice-pdf-generation`}>
            <BulkOperationPage
              title="Invoice PDF Generation"
              operationType={BULK_OPERATION_TYPES.INVOICE_PDF_GENERATION}
              isEnabled={showInvoicePdfGenerationPage}
            />
          </Route>
          <Route exact path={`${props.match?.path}/order-status-update`}>
            <BulkOperationPage
              title="Order Status Update"
              operationType={BULK_OPERATION_TYPES.ORDER_STATUS_UPDATE}
              isEnabled={showOrderStatusUpdatePage}
            />
          </Route>
          <Route exact path={`${props.match?.path}/available-yard-containers`} component={YardContainers} />
          <Route exact path={`${props.match?.path}/dual-trans-finder`} component={DualTransFinder} />
          <Route exact path={`${props.match?.path}/reactive-dual-trans`} component={ReactiveDualTrans} />
          <Route exact path={`${props.match?.path}/empty-return-acceptance`} component={EmptyReturnAcceptance} />
          <Route exact path={`${props.match?.path}/ship-etas`} component={ShipETAs} />
          <Route exact path={`${props.match?.path}/bundle-generator`} component={BundleGenerator} />
          <Route
            exact
            path={`${props.match?.path}/bundle-generator-notifications`}
            component={BundleGeneratorNotifications}
          />
          <Route exact path={`${props.match?.path}/backhaul-ready-bulk-update`} component={BackhaulReadyBulkUpdate} />
          <Route exact path={`${props.match?.path}/chassis-management`} component={ChassisManagement} />
          <Route exact path={`${props.match?.path}/chassis-pools-management`} component={ChassisPoolsManagement} />
          <Route exact path={`${props.match?.path}/on-hire-entry`} component={OnHireEntry} />
          <Route exact path={`${props.match?.path}/on-hire-list`} component={OnHireList} />
          <Route exact path={`${props.match?.path}/bulk-order-cancellation`} component={BulkOrderCancellation} />
          <Route exact path={`${props.match?.path}/appointment-booking`} component={AppointmentBooking} />
          <Route exact path={`${props.match?.path}/update-terms-of-service`} component={UpdateTermsOfService} />
        </Switch>
      </div>
    </div>
  );
}

export default React.memo(Admin);
