import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import YardIcon from '@components/deprecatedTookit/icons/fa/regular/ParkingIcon';
import BalanceScaleIcon from '@components/deprecatedTookit/icons/fa/regular/BalanceScaleIcon';
import ContainerStorageIcon from '@components/deprecatedTookit/icons/fa/regular/ContainerStorageIcon';
import ListIcon from '@components/deprecatedTookit/icons/fa/regular/ListIcon';
import KeyboardIcon from '@components/deprecatedTookit/icons/fa/regular/KeyboardIcon';
import UserIcon from '@components/deprecatedTookit/icons/fa/regular/UserIcon';
import TruckContainerIcon from '@components/deprecatedTookit/icons/fa/regular/TruckContainerIcon';
import ShipIcon from '@components/deprecatedTookit/icons/fa/regular/ShipIcon';
import CogsIcon from '@components/deprecatedTookit/icons/fa/regular/CogsIcon';
import CommentAltIcon from '@components/deprecatedTookit/icons/fa/regular/CommentAltIcon';
import LinkIcon from '@components/deprecatedTookit/icons/fa/regular/LinkIcon';
import BoxIcon from '@components/deprecatedTookit/icons/fa/regular/BoxIcon';
import TruckRampIcon from '@components/deprecatedTookit/icons/fa/regular/TruckRampIcon';
import SwimmingPoolIcon from '@components/deprecatedTookit/icons/fa/regular/SwimmingPoolIcon';
import BookIcon from '@components/deprecatedTookit/icons/fa/regular/BookIcon';
import SidebarNavLink from '../../../../../../components/SidebarNavLink';

import './style.css';

export interface AdminLeftSidebarProps {
  navId?: string;
  hideGroups?: { [key: string]: boolean };
  // eslint-disable-next-line no-unused-vars
  onNavigate?: (path: string) => void;
}

function AdminLeftSidebar(props: AdminLeftSidebarProps) {
  const featureFlags = useFlags();
  const showOnHireEntryLink = featureFlags.containerOnHireStatusLink;

  const { showLoadIdUpdatePage, showFscUpdatePage, showInvoicePdfGenerationPage, showOrderStatusUpdatePage } =
    featureFlags;

  const [state, setState] = useState({
    navId: props.navId
  });
  const baseClassName = 'AdminLeftSidebar';
  const classNames = {
    root: baseClassName,
    groupTitle: `${baseClassName}__group-title`
  };

  const handleNavLinkClick = (cProps: any) => {
    setState((prevState) => ({
      ...prevState,
      navId: cProps.id
    }));

    if (props.onNavigate) {
      props.onNavigate(cProps.id);
    }
  };

  const isNavLinkSelected = (path: string) => {
    const result = state.navId === path;

    return result;
  };

  return (
    <div className={classNames.root}>
      {props.hideGroups?.logs && (
        <>
          <div className={classNames.groupTitle}>Logs</div>

          <SidebarNavLink
            value="Action Logs"
            icon={<YardIcon />}
            id="action-logs"
            selected={isNavLinkSelected('action-logs')}
            onClick={handleNavLinkClick}
          />
        </>
      )}

      {(showFscUpdatePage || showLoadIdUpdatePage || showInvoicePdfGenerationPage || showOrderStatusUpdatePage) && (
        <div className={classNames.groupTitle}>Bulk Operations</div>
      )}
      {showFscUpdatePage && (
        <SidebarNavLink
          value="Bulk FSC Update"
          icon={<ContainerStorageIcon />}
          id="fsc-update"
          selected={isNavLinkSelected('fsc-update')}
          onClick={handleNavLinkClick}
        />
      )}
      {showLoadIdUpdatePage && (
        <SidebarNavLink
          value="Load Id Update"
          icon={<ContainerStorageIcon />}
          id="load-id-update"
          selected={isNavLinkSelected('load-id-update')}
          onClick={handleNavLinkClick}
        />
      )}
      {showInvoicePdfGenerationPage && (
        <SidebarNavLink
          value="Invoice PDF Generation"
          icon={<ContainerStorageIcon />}
          id="invoice-pdf-generation"
          selected={isNavLinkSelected('invoice-pdf-generation')}
          onClick={handleNavLinkClick}
        />
      )}
      {showOrderStatusUpdatePage && (
        <SidebarNavLink
          value="Order Status Update"
          icon={<ContainerStorageIcon />}
          id="order-status-update"
          selected={isNavLinkSelected('order-status-update')}
          onClick={handleNavLinkClick}
        />
      )}

      <div className={classNames.groupTitle}>Empty Management</div>

      <SidebarNavLink
        value="Available Empty Yard Containers"
        icon={<YardIcon />}
        id="available-yard-containers"
        selected={isNavLinkSelected('available-yard-containers')}
        onClick={handleNavLinkClick}
      />
      <SidebarNavLink
        value="Dual Trans Finder"
        icon={<LinkIcon />}
        id="dual-trans-finder"
        selected={isNavLinkSelected('dual-trans-finder')}
        onClick={handleNavLinkClick}
      />
      <SidebarNavLink
        value="Reactive Dual Trans"
        icon={<UserIcon />}
        id="reactive-dual-trans"
        selected={isNavLinkSelected('reactive-dual-trans')}
        onClick={handleNavLinkClick}
      />
      <SidebarNavLink
        value="Empty Return Acceptance"
        icon={<TruckContainerIcon />}
        id="empty-return-acceptance"
        selected={isNavLinkSelected('empty-return-acceptance')}
        onClick={handleNavLinkClick}
      />
      <SidebarNavLink
        value="Ship ETAs"
        icon={<ShipIcon />}
        id="ship-etas"
        selected={isNavLinkSelected('ship-etas')}
        onClick={handleNavLinkClick}
      />
      <SidebarNavLink
        value="Backhaul Ready Bulk Update"
        icon={<ContainerStorageIcon />}
        id="backhaul-ready-bulk-update"
        selected={isNavLinkSelected('backhaul-ready-bulk-update')}
        onClick={handleNavLinkClick}
      />

      <div className={classNames.groupTitle}>Bundle Generator</div>
      {props.hideGroups?.generator && (
        <SidebarNavLink
          value="Bundle Generator"
          icon={<CogsIcon />}
          id="bundle-generator"
          selected={isNavLinkSelected('bundle-generator')}
          onClick={handleNavLinkClick}
        />
      )}
      {props.hideGroups?.generator && (
        <SidebarNavLink
          value="Notifications"
          icon={<CommentAltIcon />}
          id="bundle-generator-notifications"
          selected={isNavLinkSelected('bundle-generator-notifications')}
          onClick={handleNavLinkClick}
        />
      )}
      <div className={classNames.groupTitle}>Scraper</div>
      <SidebarNavLink
        value="Appointment Subscriptions"
        icon={<BookIcon />}
        id="appointment-booking"
        selected={isNavLinkSelected('appointment-booking')}
        onClick={handleNavLinkClick}
      />
      <div className={classNames.groupTitle}>Chassis Management</div>
      <SidebarNavLink
        value="Chassis Management"
        icon={<TruckRampIcon />}
        id="chassis-management"
        selected={isNavLinkSelected('chassis-management')}
        onClick={handleNavLinkClick}
      />
      <SidebarNavLink
        value="Chassis Pools Management"
        icon={<SwimmingPoolIcon />}
        id="chassis-pools-management"
        selected={isNavLinkSelected('chassis-pools-management')}
        onClick={handleNavLinkClick}
      />
      <div className={classNames.groupTitle}>On-Hire</div>
      {showOnHireEntryLink && (
        <SidebarNavLink
          value="On Hire Entry"
          icon={<KeyboardIcon />}
          id="on-hire-entry"
          selected={isNavLinkSelected('on-hire-entry')}
          onClick={handleNavLinkClick}
        />
      )}

      {showOnHireEntryLink && (
        <SidebarNavLink
          value="On Hire List"
          icon={<ListIcon />}
          id="on-hire-list"
          selected={isNavLinkSelected('on-hire-list')}
          onClick={handleNavLinkClick}
        />
      )}
      <div className={classNames.groupTitle}>Orders</div>
      <SidebarNavLink
        value="Bulk Order Cancellation"
        icon={<BoxIcon />}
        id="bulk-order-cancellation"
        selected={isNavLinkSelected('bulk-order-cancellation')}
        onClick={handleNavLinkClick}
      />
      <div className={classNames.groupTitle}>Legal</div>
      <SidebarNavLink
        value="Update Terms of Service"
        icon={<BalanceScaleIcon />}
        id="update-terms-of-service"
        selected={isNavLinkSelected('update-terms-of-service')}
        onClick={handleNavLinkClick}
      />
    </div>
  );
}

export default React.memo(AdminLeftSidebar);
