import React from 'react';
import { Helmet } from 'react-helmet';
import './style.css';
import { api } from '@client/utils/url';
import request from '@client/utils/request';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast';
import { BackhaulReadyBulkUpdateInput } from '@client/_blessed/components/features/Admin/features/BackhaulReadyBulkUpdate/features/BackhaulReadyBulkUpdateInput/component';
import { BackhaulReadyBulkUpdateTables } from '@client/_blessed/components/features/Admin/features/BackhaulReadyBulkUpdate/features/BackhaulReadyBulkUpdateTables/component';
import {
  BackHaulReadyResponse,
  BackHaulReadyResponseData
} from '@client/_blessed/components/features/Admin/features/BackhaulReadyBulkUpdate/features/BackHaulReadyResponseData/types';

const pageClassName = 'backhaul-ready-bulk-update-page';

async function postBackhaulReady(containerNumbers: string[]): Promise<BackHaulReadyResponse> {
  return (await request(api('/legs/backhaul-ready'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data: { containerNumbers } })
  })) as BackHaulReadyResponse;
}

function sendAlert() {
  raiseToast(
    <SystemToast
      type={SystemToast.Type.ERROR}
      message="Unable to update legs to backhaul ready. Please try again later."
    />
  );
}

function sendSuccessNotification() {
  raiseToast(
    <SystemToast
      type={SystemToast.Type.SUCCESS}
      message="Update request sent. Please check the response tables for more details."
    />
  );
}

function Container() {
  const [data, setData] = React.useState<BackHaulReadyResponseData>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = async (containerNumbers: string[]) => {
    try {
      setLoading(true);
      const response = await postBackhaulReady(containerNumbers);
      setData(response.data);
      setLoading(false);
      sendSuccessNotification();
    } catch (e) {
      sendAlert();
      setLoading(false);
    }
  };
  return (
    <>
      <Helmet title="Backhaul Ready" />
      <div className={pageClassName}>
        <div className={`${pageClassName}__title`}>Backhaul Ready Bulk Update</div>
        <BackhaulReadyBulkUpdateInput onSubmit={handleSubmit} loading={loading} />
        <BackhaulReadyBulkUpdateTables data={data} />
      </div>
    </>
  );
}

export default React.memo(Container);
