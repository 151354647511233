import React from 'react';
import Button from '@client/components/Button';
import './style.css';

const pageClassName = 'backhaul-ready-bulk-update-input';

const convertToList = (containerNumber: string) => containerNumber.trim().split(/\n|\s|,/);

const foundMultipleDelimiters = (containerNumbers: string) => {
  let numberOfUniqueDelimiters = 0;
  const foundASpace = containerNumbers.indexOf(' ') > -1;
  const foundAComma = containerNumbers.indexOf(',') > -1;
  const foundANewLine = containerNumbers.indexOf('\n') > -1;
  if (foundASpace) {
    numberOfUniqueDelimiters += 1;
  }
  if (foundAComma) {
    numberOfUniqueDelimiters += 1;
  }
  if (foundANewLine) {
    numberOfUniqueDelimiters += 1;
  }
  return numberOfUniqueDelimiters > 1;
};

const validateInput = (containerNumbers: string) => {
  const trimedText = containerNumbers.trim();
  if (trimedText.length === 0) return false;
  return !foundMultipleDelimiters(trimedText);
};

export function BackhaulReadyBulkUpdateInput({
  onSubmit,
  loading
}: {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (containerNumbers: string[]) => void;
  loading?: boolean;
}) {
  const [containerNumbers, setContainerNumbers] = React.useState<string>('');
  const [foundInvalidInput, setFoundInvalidInput] = React.useState<boolean>(false);

  return (
    <div className={pageClassName}>
      <textarea
        value={containerNumbers}
        onChange={(e) => {
          setFoundInvalidInput(false);
          setContainerNumbers(e.target.value);
        }}
        name="container-numbers"
        id="container-numbers"
        className={`${pageClassName}__textarea ${foundInvalidInput && 'error'}`}
        spellCheck={false}
        placeholder="Enter container numbers here separated by spaces, commas, or hard returns."
        aria-label="Container numbers input"
      />
      {foundInvalidInput && (
        <div className={`${pageClassName}__error`}>
          Found invalid Inputs. Please enter container numbers separated by spaces, commas, or hard returns.
        </div>
      )}
      <Button
        theme="2"
        label="Update Leg Status"
        loading={loading}
        onClick={() => {
          if (validateInput(containerNumbers)) {
            onSubmit(convertToList(containerNumbers));
          } else {
            setFoundInvalidInput(true);
          }
        }}
      />
    </div>
  );
}
