import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Component from './component';
import history from '../../../../../../utils/history';

function Container(props: any) {
  const featFlags = useFlags();

  const handleNavigate = (navID: string) => {
    history.push(`${props.baseRoutePath}/${navID}`);
  };

  const generator = featFlags.bundleGenerator && featFlags.bundleGenerator.enabled;

  return (
    <Component
      navId={props.match?.params?.featureID}
      onNavigate={handleNavigate}
      hideGroups={{
        logs: featFlags.da5372ActionLogs,
        generator
      }}
    />
  );
}

export default React.memo(Container);
