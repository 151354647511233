import React from 'react';
import {
  BackHaulReadyResponseData,
  BackHaulReadyResponseUpdated,
  BackhaulReadyNotMeetingCriteriaResponse
} from '@client/_blessed/components/features/Admin/features/BackhaulReadyBulkUpdate/features/BackHaulReadyResponseData/types';
import './style.css';
import Button from '@client/components/Button';
import history from '@client/utils/history';
import { titleCase } from 'change-case';

const pageClassName = 'backhaul-ready-bulk-update-tables';

function UpdatedResponseTable({ data }: { data: BackHaulReadyResponseUpdated[] }) {
  if (data.length === 0) return null;
  return (
    <div>
      <div className={`${pageClassName}__table-title`}>Updated:</div>
      <table className={`${pageClassName}__table`}>
        <thead>
          <tr>
            <th>Container</th>
            <th>Job</th>
            <th>Previous Leg Status</th>
            <th>Current Leg Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((backHaulReadyResponseUpdated) => (
              <tr key={backHaulReadyResponseUpdated.container}>
                <td>{backHaulReadyResponseUpdated.container}</td>
                <td>{backHaulReadyResponseUpdated.legNumber}</td>
                <td>{titleCase(backHaulReadyResponseUpdated.previousLegStatus)}</td>
                <td>{titleCase(backHaulReadyResponseUpdated.currentLegStatus)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

function NotMeetingCriteriaResponseTable({ data }: { data: BackhaulReadyNotMeetingCriteriaResponse[] }) {
  if (data.length === 0) return null;

  return (
    <div>
      <div className={`${pageClassName}__table-title`}>
        <span>Does not meet criteria:</span>
        <Button
          variant="outlined"
          theme="1"
          label="Investigate"
          onClick={() => {
            const pathname = Buffer.from(
              JSON.stringify({ containerNumber: data.map((d) => d.containerNumber) })
            ).toString('base64');
            history.push(`/hub/dashboard/dispatch?filters=${pathname}`);
          }}
        />
      </div>
      <table className={`${pageClassName}__table`}>
        <thead>
          <tr>
            <th>Container</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          {data.map((reason) => (
              <tr key={reason.containerNumber}>
                <td>{reason.containerNumber}</td>
                <td>{reason.reason}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export function BackhaulReadyBulkUpdateTables({ data }: { data?: BackHaulReadyResponseData }) {
  if (!data) return null;
  return (
    <div className={pageClassName}>
      <UpdatedResponseTable data={data.updated} />
      <NotMeetingCriteriaResponseTable data={data.notMeetingCriteria} />
    </div>
  );
}
