import React from 'react';
import qs from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '@client/utils/url';
import request, { requestRules, isFetchCanceled } from '@client/utils/request';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast';

const retrieveChangeLogsID = `adminActionLogs/retrieveChangeLogs`;

export const retrieveChangeLogs = createAsyncThunk(
  retrieveChangeLogsID,
  async (args: { actionIDs: string[] }, thunkAPI) => {
    const options = {
      details: {
        id: retrieveChangeLogsID,
        rule: requestRules.TAKE_LAST
      }
    };

    const query = qs.stringify({
      withHydration: true,
      actionUuid: args.actionIDs
    });
    const url = api(`/changelogs?${query}`);

    try {
      const resp: any = await request(url, options);

      return resp.data;
    } catch (err) {
      if (typeof err === 'object' && err !== null && 'code' in err && typeof err.code === 'number') {
        const { code } = err;
        if (!isFetchCanceled({ code })) {
          raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to retrieve change logs" />);
          thunkAPI.rejectWithValue(err);
        }
      }
    }
  }
);
