import React from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'query-string';

// import { adminActionLogsStoreKey } from '../../';
import { api } from '@client/utils/url';
import request, { requestRules, isFetchCanceled } from '@client/utils/request';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast';

const retrieveLegActionLogsID = `adminActionLogs/retrieveLegActionLogs`;
const retrieveLegActionsByOrderID = `adminActionLogs/retrieveLegActionsByOrder`;

export const retrieveLegActionLogs = createAsyncThunk(
  retrieveLegActionLogsID,
  async (
    args: {
      legNumbers: string[] | number[];
    },
    thunkAPI: any
  ) => {
    const options = {
      details: {
        id: retrieveLegActionLogsID,
        rule: requestRules.TAKE_LAST
      }
    };

    const query = qs.stringify({
      legNumber: args.legNumbers.join(','),
      withUsers: true,
      enableSearchDeleted: true
    });

    const url = api(`/action-logs/legs?${query}`);

    try {
      const resp: any = await request(url, options);

      return resp.data;
    } catch (err) {
      if (typeof err === 'object' && err !== null && 'code' in err && typeof err.code === 'number') {
        const { code } = err;
        if (!isFetchCanceled({ code })) {
          raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to retrieve leg action logs" />);
          thunkAPI.rejectWithValue(err);
        }
      }
    }
  }
);

export const retrieveLegActionLogsByOrder = createAsyncThunk(
  retrieveLegActionsByOrderID,
  async (
    args: {
      orderNumber: string | number;
    },
    thunkAPI: any
  ) => {
    const options = {
      details: {
        id: retrieveLegActionsByOrderID,
        rule: requestRules.TAKE_LAST
      }
    };

    const query = qs.stringify({
      orderNumber: args.orderNumber,
      withUsers: true,
      enableSearchDeleted: true
    });

    const url = api(`/action-logs/orders?${query}`);

    try {
      const resp: any = await request(url, options);

      return resp.data;
    } catch (err: unknown) {
      if (typeof err === 'object' && err !== null && 'code' in err && typeof err.code === 'number') {
        const { code } = err;
        if (!isFetchCanceled({ code })) {
          raiseToast(
            <SystemToast
              type={SystemToast.Type.ERROR}
              message="Unable to retrieve leg action logs using order number"
            />
          );
          thunkAPI.rejectWithValue(err);
        }
      }
    }
  }
);
