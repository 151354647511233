// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backhaul-ready-bulk-update-page {
  padding: 40px;
}

  .backhaul-ready-bulk-update-page > * {
    margin-bottom: 30px;
  }

  .backhaul-ready-bulk-update-page__title {
    font-size: 20px;
    font-weight: 600;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BackhaulReadyBulkUpdate/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AAUf;;EARE;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".backhaul-ready-bulk-update-page {\n  padding: 40px;\n\n  & > * {\n    margin-bottom: 30px;\n  }\n\n  &__title {\n    font-size: 20px;\n    font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
